import React, { Component } from 'react';
import { Link } from 'react-router-dom';

require('./Footer.scss');

class Footer extends Component {
  render() {
    const { location } = this.props;

    return (
      <footer className="c-footer">
        <div
          className={`hidden-mobile text ${
            location.pathname === '/' ? '-white' : ' menu-text-item'
          } -bold -shadow -xl`}
        >
          <Link
            className={`text ${location.pathname === '/' ? '-white' : 'menu-text-item'}`}
            to="/news"
          >
            news
          </Link>
        </div>
        <div
          className={`hidden-mobile text ${
            location.pathname === '/' ? '-white' : 'menu-text-item'
          } -bold -shadow -xl`}
        >
          <Link
            className={`text ${location.pathname === '/' ? '-white' : '- menu-text-item'}`}
            to="/what"
          >
            what?
          </Link>
        </div>
      </footer>
    );
  }
}

export { Footer };
