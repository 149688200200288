import React, { useState, useEffect } from 'react';

const ImgOffice = (props) => {
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 });
  const onMouseMove = ({ clientX, clientY }) => {
    setPosition({ x: clientX, y: clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  const { imagePeople } = props;

 
  return (
    <div
      style={{
        top: `${y + window.scrollY}px`,
        left: `${x}px`,
        backgroundImage: `url(${imagePeople})`,
      }}
      className="l-office__image-section"
    >
      {}
    </div>
  );
};

export default ImgOffice;
