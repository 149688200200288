import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ImageLoader from 'react-load-image';
import loader from '../../assets/loader.svg';

require('./Project.scss');

class Project extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoverTitle: false,
            goHome: false
        }
    }

    getLoad = () => {
        const { checkLoad } = this.props;
        checkLoad();
    }

    render() {
        const { content, position, selected } = this.props;
        let isImageSelectedClass

        if (selected !== 'no') {
            isImageSelectedClass = selected ? 'FlexEmbed-ratio FlexEmbed-ratio--16by9' : 'FlexEmbed-ratio FlexEmbed-ratio--16by9 -selected'
        }

        return (
            <ImageLoader
                src={content.fields.imagenPortada.fields.file.url}
                style={{display: "inline-block"}}
                onLoad={(e) => this.getLoad(e)}
            >
                <Link to={`/project-detail/${content.fields.titulo.normalize("NFD").replace(/\s\s+/g, ' ').replace(/[\u0300-\u036f]/g, "").split(' ').join('-')}`} className="FlexEmbed c-project no-cursor">
                    <div
                        style={{backgroundImage: `url(${content.fields.imagenPortada.fields.file.url})`}}
                        className={'no-cursor'  + selected === 'no' ? 'FlexEmbed-ratio FlexEmbed-ratio--16by9' : isImageSelectedClass}
                    >
                        {/* {selected && */}
                        <span
                            style={{
                                left: `${position.x}px`,
                                top: `${position.y}px`
                            }}
                            className="c-project__title text -bold -s -white"
                        >
                            <p className="text -secondary -white -bold">{content.fields.titulo}</p>
                            <p className="text -secondary -white -light">{content.fields.subTitulo}</p>
                        </span>
                        {/* } */}
                    </div>
                </Link>
                <div>Error!</div>
                <div className="image-loader-container">
                    <img className="image-loader" src={loader} alt="cross loader" />
                </div>
            </ImageLoader>
        );
    }
}

export { Project };