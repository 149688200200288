import React, { Component } from 'react';
import TagCloud from 'react-tag-cloud';
import * as contentful from 'contentful';
import { Link } from 'react-router-dom';
import { analitycEvent } from '../../utilities/analytics';

import searchIcon from './assets/search.svg';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GOOGLE);
ReactGA.pageview(window.location.pathname + window.location.search);

require('dotenv').config();

const client = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

require('./What.scss');

class What extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: null,
      tagsFiltered: null,
    };
  }

  componentDidMount() {
    this.fetchPosts();

    document.getElementById('bodyBody').classList.add('black-circle');
  }

  fetchPosts = () => {
    client
      .getEntries({
        content_type: 'tag',
        limit: 1000,
      })
      .then((response) => {
        const singleTags = response.items;

        client
          .getEntries({
            content_type: 'proyecto',
            limit: 1000,
          })
          .then((response) => {
            const allTags = response.items
              .map((item) => item.fields.tag.map((t) => t.fields.titulo))
              .flat();
            const uniqueItems = this.countDuplicates(allTags);

            this.setState({
              countedTags: uniqueItems,
              tags: singleTags,
            });
          });
      });
  };

  countDuplicates = (arr) => {
    const uniqueItems = [...new Set(arr)].map((item) => ({ name: item, count: 0 }));
    arr.forEach((item) => {
      const original = uniqueItems.find((it) => it.name === item);
      if (original) {
        original.count += 1;
      }
    });

    return uniqueItems;
  };

  setPosts = (response) => {
    this.setState({
      tags: response,
    });
  };

  buildTags = () => {
    const { tags, tagsFiltered, countedTags } = this.state;
    const tagUse = tagsFiltered ? tagsFiltered : tags;
    const tagSorted = tagUse.sort(function(a, b) {
      if (a.fields.titulo < b.fields.titulo) {
        return -1;
      }
      if (a.fields.titulo > b.fields.titulo) {
        return 1;
      }
      return 0
    });
    return tagUse.map((t, i) => {
      const {
        fields: { titulo },
      } = t;
      const tagData = countedTags && countedTags.find((tag) => tag.name === titulo);
      const count = tagData ? tagData.count : 1;
      const fontSize = 12 + count * 3;
      return (
        <Link
          to={`/work/${titulo.toLowerCase()}`}
          key={i}
          style={{ fontSize }}
          className="text -secondary -black"
          onClick={() =>
            analitycEvent(
              'Interacción',
              `Visitar proyectos filtrados por: ${titulo.toLowerCase()}`,
              'Pagina what'
            )
          }
        >
          <span>{titulo}</span>
        </Link>
      );
    });
  };

  searchTag = (e) => {
    const { tags } = this.state;

    analitycEvent(
      'Interacción',
      `Buscar proyectos por: ${e.target.value.toLowerCase()}`,
      'Pagina what'
    );

    this.setState({
      tagsFiltered: tags.filter((a) =>
        a.fields.titulo.toLowerCase().includes(e.target.value.toLowerCase())
      ),
    });
  };

  render() {
    const { tags } = this.state;
    const ts = tags && tags.map((t) => t.fields.titulo);
    const set = tags && [...new Set(ts)];
    const fonts = [18, 22, 26];

    return (
      <main className="l-what">
        {tags && window.innerWidth >= 768 && (
          <TagCloud
            className="tag-cloud"
            style={{
              fontFamily: 'sans-serif',
              fontSize: () => fonts[Math.floor(Math.random() * 3)],
              padding: 5,
              position: 'absolute',
              top: 'calc(50% - 40px)',
              left: '50%',
              width: 'calc(100vw - 120px)',
              height: 'calc(100vh - 280px)',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {this.buildTags()}
          </TagCloud>
        )}
        {tags && window.innerWidth < 768 && (
          <div className="tag-cloud-mobile">{this.buildTags()}</div>
        )}
        <div className="l-what__searcher">
          <h1 className="text -black -uppercase -l">what are you looking for?</h1>
          <div className="l-what__searcher__input-container">
            <img src={searchIcon} alt="search" />
            <input
              onChange={(e) => this.searchTag(e)}
              className="l-what__searcher__input-container__input"
            />
          </div>
        </div>
      </main>
    );
  }
}

export { What };
