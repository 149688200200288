import React, { Component } from "react";
import * as contentful from "contentful";
import moment from "moment";
import ReactGA from "react-ga";
import { analitycEvent } from "../../utilities/analytics";
import ReactCursorPosition from "react-cursor-position";
import New from "./New";

ReactGA.initialize(process.env.REACT_APP_GOOGLE);
ReactGA.pageview(window.location.pathname + window.location.search);

const client = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

require("./News.scss");

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: null,
      showImage: false,
      imageToShow: null,
    };
  }

  componentDidMount() {
    this.fetchPosts().then(this.setPosts);

    document.getElementById("bodyBody").classList.add("black-circle");
  }

  fetchPosts = () =>
    client.getEntries({
      content_type: "noticia",
      limit: 1000,
    });

  setPosts = (response) => {
    this.setState({
      news: response.items
        .filter((c) => c.sys.contentType.sys.id === "noticia")
        .sort(
          (a, b) =>
            moment(b.fields.fecha).format("x") -
            moment(a.fields.fecha).format("x")
        ),
    });
  };

  hidePic = () => {
    this.setState({
      showImage: false,
      imageToShow: null,
    });
  };

  showPic = (pic) => {
    if (pic) {
      analitycEvent("Interacción", "Mostrar imagén", "Noticias");
      this.setState({
        showImage: true,
        imageToShow: pic.fields.file.url,
      });
    } else {
      analitycEvent("Interacción", "Ocultar imagén", "Noticias");
      this.hidePic();
    }
  };

  buildNews = () => {
    const { news } = this.state;

    return news.map((n, i) => {
      return (
        <ReactCursorPosition key={i}>
          <New key={i} n={n} i={i} showPic={this.showPic} hidePic={this.hidePic} />
        </ReactCursorPosition>
      );
    });
  };

  render() {
    const { news, showImage, imageToShow } = this.state;

    const newsGrid = news && this.buildNews();

    return (
      <main className="l-news">
        {showImage && (
          <div
            style={{
              backgroundImage: `url(${imageToShow})`,
            }}
            className="l-news__pic"
          />
        )}
        <div className="l-news__grid">{newsGrid}</div>
      </main>
    );
  }
}

export { News };
