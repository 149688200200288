import React, { useState, useEffect } from 'react';
import * as contentful from 'contentful';
import moment from 'moment';
import './Work.scss';
import Grid from '../../components/Grid/Grid';
import FilterBar from '../../components/Filters/FilterBar';
import ReactGA from 'react-ga';
import useFilter from '../../hooks/useFilter';
import initialFilters from './filterData';

ReactGA.initialize(process.env.REACT_APP_GOOGLE);
ReactGA.pageview(window.location.pathname + window.location.search);

const client = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const Work = ({ match }) => {
  const [projects, setProjects] = useState([]);
  const [showBackLoading, toggleBackLoading] = useState(true);
  const [{ countLoaders, percentageBar }, setLoader] = useState({
    percentageBar: 0,
    countLoaders: 0,
  });
  const [filtersCleared, clearFilters] = useState(false);
  const [filteredProjects, filterData, selectedFilters, updateFilter] = useFilter(
    projects,
    initialFilters,
    filtersCleared
  );

  const checkLoad = () => {
    const currentProjects = projects;
    setLoader({
      percentageBar: (countLoaders / (currentProjects.length - 1)) * 100,
      countLoaders: countLoaders + 1,
    });
    if (currentProjects.length - 1 <= countLoaders) {
      toggleBackLoading(false);
      setLoader({
        percentageBar: 100,
        countLoaders: 0,
      });
    }
  };

  const setPosts = (items) => {
    const getTagList = (list) => {
      if (!list) return [];
      return list.map((tag) => {
        return tag && tag.fields ? tag.fields.titulo.toLowerCase() : null;
      });
    };
    items.map((c) => {
      c.tags = getTagList(c.fields.tag);
      return c;
    });

    let postsList = [];
    if (match.params.postTag !== 'all') {
      postsList = items
        .filter((c) => getTagList(c.fields.tag).includes(match.params.postTag.toLowerCase()))
        .sort(
          (a, b) =>
            moment(b.fields.proyectoFiltroAno).format('x') -
            moment(a.fields.proyectoFiltroAno).format('x')
        );
    } else {
      postsList = items.sort(
        (a, b) =>
          moment(b.fields.proyectoFiltroAno).format('x') -
          moment(a.fields.proyectoFiltroAno).format('x')
      );
    }
    setProjects(postsList);
  };

  useEffect(() => {
    client
      .getEntries({
        content_type: 'proyecto',
        limit: 1000,
      })
      .then(({ items }) => {
        setPosts(items);
      });
    document.getElementById('bodyBody').classList.add('black-circle');
    document.getElementById('bodyBody').setAttribute('style', 'min-height: 100vh;');
  }, []);

  return (
    <main className="l-work">
      <div className="l-work__no-scroll">
        {/* TODO: Tooltip <span className="f20">Filters:</span> */}
        <div className="filters-wrapper">
          <FilterBar
            isFiltered={projects.length > filteredProjects.length}
            filteredProjects={filteredProjects}
            updateFilter={updateFilter}
            clearFilters={() => clearFilters(!filtersCleared)}
            filters={filterData}
            selectedFilters={selectedFilters}
          />
        </div>
        <Grid projects={projects} projectsFiltered={filteredProjects} checkLoad={checkLoad} />
      </div>
      {showBackLoading && (
        <div className="l-work__back-loader text -secondary -xl -bold">
          <div>cooking everything</div>
          <div className="l-work__back-loader__bar">
            <div
              style={{ width: `${percentageBar}%` }}
              className="l-work__back-loader__bar__loader"
            />
          </div>
        </div>
      )}
    </main>
  );
};

export default Work;
