import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import * as contentful from 'contentful';
import classnames from 'classnames';
import ReactPlayer from 'react-player';
import triangle from './assets/triangle.png';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { analitycEvent } from '../../utilities/analytics';
import Div100vh from 'react-div-100vh';

ReactGA.initialize(process.env.REACT_APP_GOOGLE);
ReactGA.pageview(window.location.pathname + window.location.search);

const client = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

require('./Home.scss');

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      projects: null,
      news: null,
      showPrevPic: false,
      showedPrevPic: false,
      showLastPic: false,
      showedLastPic: false,
    };
  }

  componentDidMount() {
    // this.slider.focus()
    this.fetchPostsProjects();
    this.fetchPostsNews();
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousemove', this.cursorPosition);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.cursorPosition);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    if (e) {
      if (e.keyCode === 39) {
        analitycEvent('Interacción', 'Cambiar slider, usando teclado', 'Inicio');
        this.goRightSlider();
      } else if (e.keyCode === 37) {
        analitycEvent('Interacción', 'Cambiar slider, usando teclado', 'Inicio');
        this.goLeftSlider();
      }
    }
  };

  cursorPosition = (e) => {
    const { showedPrevPic, showedLastPic } = this.state;

    if (e.screenX > window.innerWidth - 200 && !showedPrevPic) {
      analitycEvent('Interacción', 'Mostrar imagen del siguiente slider', 'Inicio');
      this.setState({
        showPrevPic: true,
        showedPrevPic: true,
      });
    } else if (e.screenX < window.innerWidth - 200 && showedPrevPic) {
      this.setState({
        showPrevPic: false,
        showedPrevPic: false,
      });
    }

    if (e.screenX < 200 && !showedLastPic) {
      analitycEvent('Interacción', 'Mostrar imagen del anterior slider', 'Inicio');
      this.setState({
        showLastPic: true,
        showedLastPic: true,
      });
    } else if (e.screenX > 200 && showedLastPic) {
      this.setState({
        showLastPic: false,
        showedLastPic: false,
      });
    }
  };

  fetchPostsProjects = () => {
    client
      .getEntries({
        content_type: 'slider',
      })
      .then((response) => {
        this.setPostsProjects(response.items);
      });
  };

  fetchPostsNews = () => {
    client
      .getEntries({
        content_type: 'noticia',
      })
      .then((response) => {
        this.setPostsNews(response.items);
      });
  };

  setPostsProjects = (response) => {
    this.setState({
      projects: response.sort((a, b) => a.fields.orden - b.fields.orden),
    });
  };

  setPostsNews = (response) => {
    this.setState({
      news: response.slice(0, 4),
    });
  };

  changeSlide = (currentSlide) => {
    this.setState(
      {
        currentSlide,
      },
      () => {
        analitycEvent('Interacción', 'Cambiar Slider', 'Inicio');
        this.slider.slickGoTo(currentSlide);
      }
    );
  };

  buildSlideContent = () => {
    const { projects, currentSlide } = this.state;

    return projects.map((content, index) => {
      return (
        <div key={index} className="l-home__slider-item">
          <div
            className="l-home__slider-item__cover-image"
            // style={{
            //     backgroundImage: `url(${content.fields.imagenPortada.fields.file.url})`,
            // }}
          >
            <ReactPlayer
              playing={index === currentSlide}
              loop={index === currentSlide}
              width="100%"
              height="100vh"
              muted
              volume={0}
              className="l-home__video-player"
              controls={false}
              url={content.fields.video.fields.file.url}
            />
          </div>
        </div>
      );
    });
  };

  buildDotsSlider = () => {
    const { projects, currentSlide } = this.state;
    return projects.map((content, index) => {
      const dotClassname = classnames('l-home__dots-container__dot', {
        '-selected': currentSlide === index,
      });

      const urlImage = content.fields.imagenPortada
        ? content.fields.imagenPortada.fields.file.url
        : null;

      return (
        <li
          key={index}
          onClick={() => this.changeSlide(index)}
          className={dotClassname}
          onMouseOver={() => analitycEvent('Interacción', 'Mostrar imagen dot slider', 'Inicio')}
        >
          <div
            className="l-home__dots-container__dot__image"
            style={{
              backgroundImage: `url(${urlImage})`,
            }}
          ></div>
        </li>
      );
    });
  };

  getTitleProyect = () => {
    const { currentSlide, projects } = this.state;

    if (!projects) return null;

    return (
      <Fragment>
        <div>
          {
            <a
              className="text -xs -white -shadow relative-mobile-video-title"
              target={projects[currentSlide].fields.urlVideo ? `_blank` : ''}
              href={
                projects[currentSlide].fields.urlVideo ? projects[currentSlide].fields.urlVideo : ''
              }
              onClick={() => analitycEvent('Interacción', 'Visitar vídeo slider', 'Inicio')}
            >
              {projects[currentSlide].fields.titulo}
            </a>
          }
        </div>
        {projects[currentSlide].fields.proyecto && projects[currentSlide].fields.mostrarProyecto && (
          <Link
            to={`/project-detail/${projects[currentSlide].fields.proyecto.fields.titulo
              .normalize('NFD')
              .replace(/\s\s+/g, ' ')
              .replace(/[\u0300-\u036f]/g, '')
              .split(' ')
              .join('-')}`}
            className="text -xs -white -shadow link-slider-project relative-mobile-title"
            onClick={() => analitycEvent('Interacción', 'Visitar proyecto slider', 'Inicio')}
          >
            {projects[currentSlide].fields.proyecto.fields.titulo}
          </Link>
        )}
      </Fragment>
    );
  };

  buildSliderArrow = () => {
    return null;
  };

  buildSlickConfiguration = () => {
    return {
      dots: true,
      infinite: true,
      speed: 500,
      accessibility: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (index) => this.changeSlide(index),
      appendDots: () => (
        <div className="l-home__dots-container">
          <ul className="l-home__dots-container__ul-dots">{this.buildDotsSlider()}</ul>
          <div className="text -white -bold -s -shadow -left">{this.getTitleProyect()}</div>
        </div>
      ),
    };
  };

  buildSlickNewsConfiguration = () => {
    return {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 5000,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
  };

  buildSlider = () => {
    const { projects } = this.state;

    if (projects) {
      const slickConfiguration = this.buildSlickConfiguration();
      const contentSlider = this.buildSlideContent();
      return (
        <Slider ref={(slider) => (this.slider = slider)} {...slickConfiguration}>
          {contentSlider}
        </Slider>
      );
    } else {
      return (
        <div className="l-home__loading-container">
          <div className="text -bold -l">loading...</div>
        </div>
      );
    }
  };

  makeRepeated = (arr, repeats) => [].concat(...Array.from({ length: repeats }, () => arr));

  buildNewsSlider = () => {
    const { news } = this.state;

    if (news) {
      let allNews = news.map((ne, index) => {
        return (
          <a
            href={`${ne.fields.linkNoticia ? ne.fields.linkNoticia : '#'}`}
            target={`${ne.fields.linkNoticia ? '_blank' : ''}`}
            className="l-home__news-slider__item text -white -bold -xxs"
            key={index}
          >
            {ne.fields.titulo}
            {ne.fields.subTitulo && ': '}
            {ne.fields.subTitulo}
          </a>
        );
      });

      return (
        <div className="l-home__news-slider__container">
          <Slider {...this.buildSlickNewsConfiguration()}>{allNews}</Slider>
        </div>
      );
    }

    return null;
  };

  goRightSlider = () => {
    const { projects, currentSlide } = this.state;
    const newSlide = currentSlide === projects.length - 1 ? 0 : currentSlide + 1;

    this.setState(
      {
        currentSlide: newSlide,
      },
      () => {
        analitycEvent('Interacción', 'Cambiar al siguiente slider', 'Inicio');
        this.slider.slickGoTo(newSlide);
      }
    );
  };

  goLeftSlider = () => {
    const { projects, currentSlide } = this.state;
    const newSlide = currentSlide === 0 ? projects.length - 1 : currentSlide - 1;

    this.setState(
      {
        currentSlide: newSlide,
      },
      () => {
        analitycEvent('Interacción', 'Cambiar al anterior slider', 'Inicio');
        this.slider.slickGoTo(newSlide);
      }
    );
  };

  render() {
    const { currentSlide, projects, showPrevPic, showLastPic } = this.state;
    const slider = this.buildSlider();
    const news = this.buildNewsSlider();

    return (
      <Fragment>

        <Div100vh class="l-home" as="main">
          {projects && (
            <div
              onClick={() => this.goLeftSlider()}
              style={{
                backgroundImage: `url(${
                  projects[currentSlide === 0 ? projects.length - 1 : currentSlide - 1].fields
                    .imagenPortada.fields.file.url
                })`,
              }}
              className={`l-home__next-last-image ${showLastPic ? '-show' : '-hidden'}`}
            />
          )}
          <img
            src={triangle}
            className="l-home__triangle-left"
            onClick={() => this.goLeftSlider()}
            alt="triangle-left"
          />
          {slider}
          <img
            src={triangle}
            className="l-home__triangle-right"
            onClick={() => this.goRightSlider()}
            alt="triangle-right"
          />
          {news}
          {projects && (
            <div
              onClick={() => this.goRightSlider()}
              style={{
                backgroundImage: `url(${
                  projects[currentSlide === projects.length - 1 ? 0 : currentSlide + 1].fields
                    .imagenPortada.fields.file.url
                })`,
              }}
              className={`l-home__next-previous-image ${showPrevPic ? '-show' : '-hidden'}`}
            />
          )}
        </Div100vh>
      </Fragment>
    );
  }
}

export { Home };
