import { useState, useEffect } from 'react';
import { analitycEvent } from '../utilities/analytics';
import moment from 'moment';

export const getNestedProp = (path, obj) =>
  path.split('.').reduce((acc, curr) => {
    return Array.isArray(acc) ? acc.map((o) => o[curr]) : acc[curr] ? acc[curr] : null;
  }, obj);

export const arrGroupBy = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) acc[key] = [];
    acc[key].push(obj);
    return acc;
  }, {});
};

const toYear = (y) => moment(y).format('YYYY');

const useFilter = (originalData, initialFilters, clear) => {
  const [data, setData] = useState(originalData);
  const [filters, setFilters] = useState(initialFilters);
  const [selectedFilters, selectFilters] = useState([]);

  const setAllFilters = (fData = filters, parent, doFilters) => {
    const setted = fData.map((f) => {
      const { children, field } = f;
      if (parent) {
        f.parent = `${parent.parent ? parent.parent + '.' : ''}${parent.label || ''}`;
      }
      f.selected = doFilters ? !!doFilters.find((sF) => sF.field === field) : false;
      const filtered = filterForEach(f, parent && parent.data, doFilters);
      f.count = filtered.length;
      f.data = filtered;
      if (children && children.length) {
        setAllFilters(children, f, doFilters);
      } else if (children && !children.length) {
        delete f.children;
      }
      return f;
    });

    return setted;
  };

  const clearFilters = (fData) => {
    const setted = setAllFilters();
    setFilters(setted);
    selectFilters([]);
    return setted;
  };

  const filterForEach = (f, parentData = originalData, doFilters) => {
    const { field, value = true, date } = f;
    if (field) {
      const filtered = parentData.filter((d) => {
        const selected = selectedFilters.findIndex((sF) => sF.field === field);
        if (doFilters && false) {
          // const filterby = selected >= 0 ? doFilters.slice(0, selected): doFilters;
          // if (selected >= 0) console.log(selected, f.label, filterby)
          const filtersGrouped = arrGroupBy(doFilters, 'group');
          return Object.keys(filtersGrouped).some((key) =>
            filtersGrouped[key].every(({ field, value = true, date }) => {
              let prop = getNestedProp(field, d);
              if (date) {
                prop = toYear(prop);
              }
              return prop === value;
            })
          );
        } else {
          let prop = getNestedProp(field, d);
          if (date) {
            prop = toYear(prop);
          }
          return prop == value;
        }
      });
      return filtered;
    }
    return parentData;
  };

  const selectFilter = (f) => {
    const { parent, label, group } = f;
    analitycEvent('Interacción', `Cambiar filtro: ${group} ${label}`, 'Proyectos');

    // add or remove from selected filters
    const exists = selectedFilters.find((sF) => sF.label === label);
    let newSelectedFilters = [...selectedFilters].filter((sF) => sF.parent !== parent);
    if (exists) {
      newSelectedFilters = selectedFilters.filter((sF) => sF.label !== label);
    } else {
      newSelectedFilters.push(f);
    }
    selectFilters(newSelectedFilters);

    const filtersToSet = setAllFilters(undefined, undefined, newSelectedFilters);
    setFilters(filtersToSet);
  };

  const filterAll = () => {
    const newData = [...originalData];
    let dataFiltered = newData;
    if (selectedFilters.length > 0) {
      const filtersGrouped = arrGroupBy(selectedFilters, 'group');
      dataFiltered = newData.filter((d) =>
        Object.keys(filtersGrouped).some((key) =>
          filtersGrouped[key].every(({ field, value = true, date }) => {
            let prop = getNestedProp(field, d);
            if (date) {
              prop = toYear(prop);
            }
            return prop === value;
          })
        )
      );
    }
    setData(dataFiltered);
  };

  useEffect(() => {
    clearFilters();
  }, [originalData, clear]);

  useEffect(() => {
    filterAll();
  }, [filters, selectedFilters]);

  return [data, filters, selectedFilters, selectFilter];
};

export default useFilter;
