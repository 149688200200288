import React from 'react';

import ReactCursorPosition from 'react-cursor-position';
import { Project } from '../Project/Project';

const Grid = ({ projectsFiltered, projects, checkLoad }) => {
  const projectMostrarEnGrid = projects
    ? projects.filter(
        (e) => e.fields.mostrarEnGrid === true || e.fields.mostrarEnGrid === undefined
      )
    : projects;
  const currentProjects = projectsFiltered ? projectsFiltered : projectMostrarEnGrid;

  if (currentProjects) {
    const idsSelected =
      projectsFiltered &&
      projectsFiltered.map((c) => {
        return c.sys.id;
      });

    return currentProjects
      .sort((a, b) => a.fields.orden - b.fields.orden)
      .map((content, index) => {
        if (!projectsFiltered || idsSelected.includes(content.sys.id)) {
          return (
            <ReactCursorPosition className="project-grid no-cursor" key={index}>
              <Project
                selected={idsSelected ? idsSelected.includes(content.sys.id) : 'no'}
                content={content}
                checkLoad={() => checkLoad()}
              />
            </ReactCursorPosition>
          );
        } else return null;
      });
  } else {
    return null;
  }
};

export default Grid;
