import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import * as contentful from 'contentful';
import { Rnd } from 'react-rnd';
import Markdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import { analitycEvent } from '../../utilities/analytics';

import triangle from './assets/triangle.png';
import plus from './assets/plus.svg';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GOOGLE);
ReactGA.pageview(window.location.pathname + window.location.search);

const client = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

require('./ProjectDetail.scss');

class ProjectDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      project: null,
      news: null,
      openAbstract: false,
      openCredit: false,

      showPrevPic: false,
      showedPrevPic: false,
      showLastPic: false,
      showedLastPic: false,

      dragAbstract: false,
      dragCredit: false,

      widthC: window.innerWidth > 768 ? 220 : '50%',
      heightC: 30,
      xC: window.innerWidth > 768 ? 100 : 0,
      yC: window.innerWidth > 768 ? 200 : window.innerHeight - 30,

      widthA: window.innerWidth > 768 ? 220 : '50%',
      heightA: 30,
      xA: window.innerWidth > 768 ? 100 : window.innerWidth / 2,
      yA: window.innerWidth > 768 ? 300 : window.innerHeight - 30,
    };
  }

  componentDidMount() {
    this.fetchPosts();
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousemove', this.cursorPosition);
  }

  componentWillUnmount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mousemove', this.cursorPosition);
  }

  handleKeyDown = (e) => {
    if (e) {
      if (e.keyCode === 39) {
        analitycEvent('Interacción', 'Cambiar slider, usando teclado', 'Detalle Proyecto');
        this.goRightSlider();
      } else if (e.keyCode === 37) {
        analitycEvent('Interacción', 'Cambiar slider, usando teclado', 'Detalle Proyecto');
        this.goLeftSlider();
      }
    }
  };

  cursorPosition = (e) => {
    const { showedPrevPic, showedLastPic } = this.state;

    if (e.screenX > window.innerWidth - 200 && !showedPrevPic) {
      analitycEvent('Interacción', 'Mostrar imagen del siguiente slider', 'Detalle Proyecto');
      this.setState({
        showPrevPic: true,
        showedPrevPic: true,
      });
    } else if (e.screenX < window.innerWidth - 200 && showedPrevPic) {
      this.setState({
        showPrevPic: false,
        showedPrevPic: false,
      });
    }

    if (e.screenX < 200 && !showedLastPic) {
      analitycEvent('Interacción', 'Mostrar imagen del anterior slider', 'Detalle Proyecto');
      this.setState({
        showLastPic: true,
        showedLastPic: true,
      });
    } else if (e.screenX > 200 && showedLastPic) {
      this.setState({
        showLastPic: false,
        showedLastPic: false,
      });
    }
  };

  fetchPosts = () => {
    client
      .getEntries({
        content_type: 'proyecto',
        limit: 1000,
      })
      .then((response) => {
        this.setPosts(response.items);
      });
  };

  setPosts = (response) => {
    this.setState({
      project: response.filter(
        (c) =>
          c.fields.titulo
            .normalize('NFD')
            .replace(/\s\s+/g, ' ')
            .replace(/[\u0300-\u036f]/g, '')
            .split(' ')
            .join('-') === this.props.match.params.postId
      )[0],
    });
  };

  changeSlide = (currentSlide) => {
    this.setState(
      {
        currentSlide,
      },
      () => {
        analitycEvent('Interacción', 'Cambiar Slider', 'Detalle Proyecto');
        this.slider.slickGoTo(currentSlide);
      }
    );
  };

  buildDotsSlider = () => {
    const { project, currentSlide } = this.state;
    let dots = [];

    // dots.push(
    //     <li
    //         key={0}
    //         onClick={() => this.changeSlide(0)}
    //         className={`l-home__dots-container__dot ${currentSlide === 0 ? '-selected' : ''}`}
    //     >
    //         <div
    //             className="l-home__dots-container__dot__image -project-detail"
    //             onMouseOver={() => analitycEvent('Interacción', 'Mostrar imagen dot slider', 'Detalle Proyecto')}
    //             style={{
    //                 backgroundImage: `url(${project.fields.imagenPortada.fields.file.url})`
    //             }}
    //         >
    //         </div>
    //     </li>
    // )

    if (project.fields.imagesSlider) {
      dots.push(
        project.fields.imagesSlider.map((c, index) => {
          return (
            <li
              key={index + 1}
              onClick={() => this.changeSlide(index)}
              className={`l-home__dots-container__dot ${currentSlide === index ? '-selected' : ''}`}
            >
              <div
                className="l-home__dots-container__dot__image -project-detail"
                style={{
                  backgroundImage: `url(${c.fields.file.url})`,
                }}
              ></div>
            </li>
          );
        })
      );
    }

    return dots;
  };

  buildSlickConfiguration = () => {
    return {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: false,
      afterChange: (index) => this.changeSlide(index),
      // nextArrow: () => (this.buildSliderArrow()),
      // prevArrow: () => (this.buildSliderArrow()),
      appendDots: () => (
        <div className="l-home__dots-container">
          <ul>{this.buildDotsSlider()}</ul>
        </div>
      ),
    };
  };

  buildSlideContent = () => {
    const { project } = this.state;
    let firstSlider = [];

    // firstSlider.push(
    //     <div
    //         className="l-home__slider-item"
    //     >
    //         <div
    //             className="l-home__slider-item__cover-image -project-detail"
    //             style={{
    //                 backgroundImage: `url(${project.fields.imagenPortada.fields.file.url})`,
    //             }}
    //         >
    //         </div>
    //     </div>
    // )

    if (project.fields.imagesSlider) {
      firstSlider.push(
        project.fields.imagesSlider.map((c, index) => {
          return (
            <div key={index} className="l-home__slider-item">
              <div
                className="l-home__slider-item__cover-image -project-detail"
                style={{
                  backgroundImage: `url(${c.fields.file.url})`,
                }}
              ></div>
            </div>
          );
        })
      );
    }

    return firstSlider;
  };

  buildSlider = () => {
    const { project } = this.state;

    if (project) {
      const slickConfiguration = this.buildSlickConfiguration();
      const contentSlider = this.buildSlideContent();
      return (
        <Slider ref={(slider) => (this.slider = slider)} {...slickConfiguration}>
          {contentSlider}
        </Slider>
      );
    } else {
      return (
        <div className="l-home__loading-container">
          <div className="text -bold -l">loading...</div>
        </div>
      );
    }
  };

  openAbstract = () => {
    const { openAbstract, dragAbstract, openCredit } = this.state;

    this.setState(
      {
        openAbstract: dragAbstract ? openAbstract : !openAbstract,
        openCredit: window.innerWidth <= 768 ? false : openCredit,
        dragAbstract: false,
        // openCredit: false
      },
      () => {
        if (!dragAbstract) {
          if (!openAbstract) {
            analitycEvent('Interacción', 'Abrir abstract', 'Detalle Proyecto');
            if (window.innerWidth > 768) {
              this.setState({
                heightA: this.state.heightA <= 100 ? 350 : this.state.heightA,
                widthA: this.state.widthA <= 220 ? 500 : this.state.widthA,
              });
            } else {
              this.setState({
                heightA: '75%',
                widthA: '90%',
                xA: 20,
                yA: 55,
                // openCredit: false
              });
            }
          } else {
            analitycEvent('Interacción', 'Cerrar abstract', 'Detalle Proyecto');
            if (window.innerWidth > 768) {
              this.setState({
                heightA: 30,
                widthA: 220,
                // openCredit: false
              });
            } else {
              this.setState({
                widthA: '50%',
                heightA: 30,
                xA: window.innerWidth / 2,
                yA: window.innerHeight - 30,
                // openCredit: false
              });
            }
          }
        }
      }
    );
  };

  openCredit = () => {
    const { openCredit, openAbstract, dragCredit } = this.state;

    this.setState(
      {
        openCredit: dragCredit ? openCredit : !openCredit,
        openAbstract: window.innerWidth <= 768 ? false : openAbstract,
        dragCredit: false,
        // openAbstract: false
      },
      () => {
        if (!dragCredit) {
          if (!openCredit) {
            analitycEvent('Interacción', 'Abrir creditos', 'Detalle Proyecto');
            if (window.innerWidth > 768) {
              this.setState({
                heightC: this.state.heightC <= 100 ? 350 : this.state.heightC,
                widthC: this.state.widthC <= 220 ? 500 : this.state.widthC,
              });
            } else {
              this.setState({
                heightC: '75%',
                widthC: '90%',
                xC: 20,
                yC: 55,
                // openAbstract: false
              });
            }
          } else {
            analitycEvent('Interacción', 'Cerrar creditos', 'Detalle Proyecto');
            if (window.innerWidth > 768) {
              this.setState({
                heightC: 30,
                widthC: 220,
                // openAbstract: false
              });
            } else {
              this.setState({
                widthC: '50%',
                heightC: 30,
                xC: 0,
                yC: window.innerHeight - 30,
                // openAbstract: false
              });
            }
          }
        }
      }
    );
  };

  continueStatusAbstract = () => {
    analitycEvent('Interacción', 'Mover abstract', 'Detalle Proyecto');
    this.setState({
      dragAbstract: true,
    });
  };

  continueStatusCredit = () => {
    analitycEvent('Interacción', 'Mover creditos', 'Detalle Proyecto');
    this.setState({
      dragCredit: true,
    });
  };

  buildContentBoxAbstract = () => {
    const { openAbstract, project } = this.state;

    return (
      <Fragment>
        <div
          className={`l-project-detail__accordion abstract ${!openAbstract ? '-no-scroll' : ''}`}
        >
          <div
            onClick={() => this.openAbstract()}
            className="l-project-detail__accordion__title text -black -uppercase -xs -pointer"
          >
            <img
              className={`l-project-detail__accordion__title__icon ${openAbstract ? '-open' : ''}`}
              src={plus}
              alt="plus"
            />
            <div>Abstract</div>
          </div>
          <div
            style={{
              display: openAbstract ? 'block' : 'none',
            }}
            className="l-project-detail__accordion__content"
          >
            {project && project.fields.abstract && <Markdown source={project.fields.abstract} />}
          </div>
        </div>
        {openAbstract && window.innerWidth < 768 && (
          <div className="box-markdown-mobile">
            <Markdown source={project.fields.abstract} />
          </div>
        )}
      </Fragment>
    );
  };

  buildAbstractContent = () => {
    const { openAbstract, project } = this.state;

    if (!project) return null;

    if (project.fields.abstract) {
      if (window.innerWidth >= 768) {
        return (
          <Rnd
            size={{ width: this.state.widthA, height: this.state.heightA }}
            position={{ x: this.state.xA, y: this.state.yA }}
            onDragStop={(e, d) => {
              this.setState({ xA: d.x, yA: d.y });
            }}
            onResize={(e, direction, ref, delta, position) => {
              this.setState({
                widthA: ref.offsetWidth,
                heightA: ref.offsetHeight,
                ...position,
              });
            }}
            minWidth={window.innerWidth > 768 ? 200 : 'auto'}
            minHeight={30}
            style={{ zIndex: 99999, position: 'fixed' }}
            onDrag={() => this.continueStatusAbstract()}
          >
            {this.buildContentBoxAbstract()}
          </Rnd>
        );
      } else {
        return (
          <div className="abstract-box-content-mobile" onClick={() => this.openAbstract()}>
            {this.buildContentBoxAbstract()}
          </div>
        );
      }
    } else {
      return null;
    }
  };

  buildContentBoxCredit = () => {
    const { openCredit, project } = this.state;

    if (!project) return null;

    return (
      <Fragment>
        <div className={`l-project-detail__accordion credit ${!openCredit ? '-no-scroll' : ''}`}>
          <div>
            <div
              onClick={() => this.openCredit()}
              className="l-project-detail__accordion__title text -black -uppercase -xs -pointer"
            >
              <img
                className={`l-project-detail__accordion__title__icon ${openCredit ? '-open' : ''}`}
                src={plus}
                alt="plus"
              />
              <div>Credits</div>
            </div>
            <div
              style={{
                display: openCredit ? 'block' : 'none',
              }}
              className="l-project-detail__accordion__content"
            >
              {project && project.fields.credits && <Markdown source={project.fields.credits} />}
            </div>
          </div>
        </div>
        {openCredit && window.innerWidth < 768 && (
          <div className="box-markdown-mobile">
            <Markdown source={project.fields.credits} />
          </div>
        )}
      </Fragment>
    );
  };

  buildCreditContent = () => {
    const { openCredit, project } = this.state;

    if (!project) return null;

    if (project.fields.credits) {
      if (window.innerWidth >= 768) {
        return (
          <Rnd
            size={{ width: this.state.widthC, height: this.state.heightC }}
            position={{ x: this.state.xC, y: this.state.yC }}
            onDragStop={(e, d) => {
              window.innerWidth > 768 && this.setState({ xC: d.x, yC: d.y });
            }}
            onResize={(e, direction, ref, delta, position) => {
              this.setState({
                widthC: ref.offsetWidth,
                heightC: ref.offsetHeight,
                ...position,
              });
            }}
            minWidth={window.innerWidth > 768 ? 200 : 'auto'}
            minHeight={30}
            style={{ zIndex: 99999, position: 'fixed', transform: 'initial', bottom: '0px' }}
            onDrag={() => this.continueStatusCredit()}
          >
            {this.buildContentBoxCredit()}
          </Rnd>
        );
      } else {
        return (
          <div className="credit-box-content-mobile" onClick={() => this.openCredit()}>
            {this.buildContentBoxCredit()}
          </div>
        );
      }
    } else {
      return null;
    }
  };

  goRightSlider = () => {
    const { project, currentSlide } = this.state;
    const newSlide = currentSlide === project.fields.imagesSlider.length ? 0 : currentSlide + 1;

    analitycEvent('Interacción', 'Cambiar al siguiente slider', 'Detalle Proyecto');

    this.setState(
      {
        currentSlide: newSlide,
      },
      () => {
        this.slider.slickGoTo(newSlide);
      }
    );
  };

  goLeftSlider = () => {
    const { project, currentSlide } = this.state;
    const newSlide = currentSlide === 0 ? project.fields.imagesSlider.length - 1 : currentSlide - 1;

    analitycEvent('Interacción', 'Cambiar al anterior slider', 'Detalle Proyecto');

    this.setState(
      {
        currentSlide: newSlide,
      },
      () => {
        this.slider.slickGoTo(newSlide);
      }
    );
  };

  buildImagesMobile = () => {
    const { project } = this.state;
    let firstSlider = [];

    if (project) {
      // firstSlider.push(
      //     <img className="l-project-detail__grid-mobile__image" src={project.fields.imagenPortada.fields.file.url} />
      // )

      if (project && project.fields && project.fields.imagesSlider) {
        firstSlider.push(
          project.fields.imagesSlider.map((c, index) => {
            return (
              <img
                className="l-project-detail__grid-mobile__image"
                src={c.fields.file.url}
                key={index}
              />
            );
          })
        );
      }
    }

    return firstSlider;
  };

  render() {
    // const { currentSlide, project, showPrevPic, showLastPic } = this.state;
    const { match } = this.props;
    const abstractAccordion = this.buildAbstractContent();
    const creditAccordion = this.buildCreditContent();

    return (
      <Fragment>
        <Helmet>
          <title>{match.params.postId}</title>
        </Helmet>
        <main className="l-project-detail">
          {/* {project && project.fields.imagesSlider &&
                        <div
                            onClick={() => this.goLeftSlider()}
                            style={{
                                backgroundImage: `url(${project.fields.imagesSlider[currentSlide === 0 ? (project.fields.imagesSlider.length - 1) : currentSlide - 1].fields.file.url})`
                            }}
                            className={`l-home__next-last-image ${showLastPic ? '-show' : '-hidden'}`}
                        />
                    } */}
          {abstractAccordion}
          {creditAccordion}
          <img
            src={triangle}
            className="l-project-detail__triangle-left"
            onClick={() => this.goLeftSlider()}
            alt="triangle-left"
          />
          {this.state.project && this.buildSlider()}
          <img
            src={triangle}
            className="l-project-detail__triangle-right"
            onClick={() => this.goRightSlider()}
            alt="triangle-right"
          />
          {/* {project && project.fields.imagesSlider &&
                        <div
                            onClick={() => this.goRightSlider()}
                            style={{
                                backgroundImage: `url(${project.fields.imagesSlider[currentSlide === (project.fields.imagesSlider.length - 1) ? 0 : currentSlide + 1].fields.file.url})`
                            }}
                            className={`l-home__next-previous-image ${showPrevPic ? '-show' : '-hidden'}`}
                        />
                    } */}
          <div className="l-project-detail__grid-mobile">{this.buildImagesMobile()}</div>
        </main>
      </Fragment>
    );
  }
}

export { ProjectDetail };
