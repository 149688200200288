const yearFilters = (key = 'proyecto') =>
  new Array(50).fill(1990).map((_, i) => ({
    label: 1990 + i,
    field: `fields.${key}FiltroAno`,
    group: key,
    value: 1990 + i,
    date: true,
  }));

const statusFilters = (key = 'proyecto') => [
  {
    label: 'Completed',
    field: `fields.${key}StatusCompletado`,
    group: key,
  },
  {
    label: 'Competition',
    field: `fields.${key}StatusCompeticion`,
    group: key,
  },
  {
    label: 'Construction',
    field: `fields.${key}StatusConstruccion`,
    group: key,
  },
];
const categoryProyectFilters = [
  {
    label: 'Urban Planning',
    field: `fields.proyectoStatusUrbanPlanning`,
    group: 'proyecto',
  },
  {
    label: 'Housing',
    field: `fields.proyectoTypologyHousing`,
    group: 'proyecto',
  },
  {
    label: 'Heritage',
    field: `fields.proyectoTypologyHeritage`,
    group: 'proyecto',
  },
  {
    label: 'Public Buildings',
    field: `fields.proyectoTypologyPublicBuildings`,
    group: 'proyecto',
  },
  {
    label: 'Competition',
    field: `fields.proyectoStatusCompeticion`,
    group: 'proyecto',
  },
  {
    label: 'Exhibitions',
    field: `fields.proyectoStatusExhibitions`,
    group: 'proyecto',
  },
];
const categoryResearchFilters = [
  {
    label: 'Workshops',
    field: `fields.reseachCategoryWorkshops`,
    group: 'research',
  },
  {
    label: 'Books',
    field: `fields.reseachCategoryBooks`,
    group: 'research',
  },
  {
    label: 'Articles',
    field: `fields.$reseachCategoryArticles`,
    group: 'research',
  },
  {
    label: 'Others',
    field: `fields.reseachCategoryOthers`,
    group: 'research',
  },
];
const categoryCommunicationFilters = [
  {
    label: 'Exhibitions',
    field: `fields.comunicacionCategoryExhibitions`,
    group: 'comunicacion',
  },
  {
    label: 'Publications',
    field: `fields.comunicacionCategoryPublications`,
    group: 'comunicacion',
  },
  {
    label: 'Others',
    field: `fields.comunicacionCategoryOthers`,
    group: 'comunicacion',
  },
];
const getCategories = (key = 'proyecto') => {
  switch (key) {
    case 'proyecto':
      return categoryProyectFilters;
    case 'reseach':
      return categoryResearchFilters;
    case 'comunicacion':
      return categoryCommunicationFilters;
    default:
      return [];
  }
};
const subFilters = (key = 'proyecto') => {
  const arr = [
    {
      label: 'Category',
      children: getCategories(key),
    },
    {
      label: 'Status',
      children: statusFilters(key),
    },
    {
      label: 'Years',
      children: yearFilters(key),
    },
  ];
  return key === 'proyecto' ? arr : [arr[0], arr[2]];
};
const initialFilters = [
  {
    label: 'Projects',
    field: 'fields.esUnProyecto',

    group: 'proyecto',
    children: subFilters('proyecto'),
  },
  {
    label: 'Research',
    field: 'fields.esResearch',

    group: 'research',
    children: subFilters('research'),
  },
  {
    label: 'Communication',
    field: 'fields.esComunicacion',

    group: 'comunicacion',
    children: subFilters('comunicacion'),
  },
];


export default initialFilters