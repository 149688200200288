import React, { Component, Fragment } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Home } from './views/Home/Home';
import Work from './views/Work/Work';
import { What } from './views/What/What';
import { Office } from './views/Office/Office';
import { News } from './views/News/News';
import { ProjectDetail } from './views/ProjectDetail/ProjectDetail';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { NotFoundPage } from './views/NotFoundPage/NotFoundPage';
import Cursor from './components/Cursor/Cursor';
// import PageTransition from '@steveeeie/react-page-transition';
import * as contentful from 'contentful';

const client = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animations: null,
    };
  }

  componentDidMount() {
    this.fetchPosts().then(this.setPosts);
  }

  fetchPosts = () => client.getEntries();

  setPosts = (response) => {
    this.setState({
      animations: response.items.filter((c) => c.sys.contentType.sys.id === 'transicionPagina'),
    });
  };

  render() {
    const { animations } = this.state;

    if (animations) {
      return (
          <BrowserRouter>
            <Route render={({ location, history }) => (
              <Fragment>
                <Helmet>
                  <title>amanncanovasmaruri</title>
              </Helmet>
                <Header location={location} history={history} />
                {/* <PageTransition
                                  preset="slide"
                                  transitionKey={location.pathname}
                              > */}
                <Cursor />
                <Switch location={location}>
                  <Route exact path="/" component={Home} />x
                  <Route exact path="/work/:postTag/" component={Work} />
                  <Route exact path="/office" component={Office} />
                  <Route exact path="/news" component={News} />
                  <Route exact path="/what" component={What} />
                  <Route exact path="/project-detail/:postId/" component={ProjectDetail} />
                  <Route component={NotFoundPage} />
              </Switch>
                {/* </PageTransition> */}
                <Footer location={location} />
            </Fragment>
            )}
          />
        </BrowserRouter>
      );
    } else {
      return null;
    }
  }
}

export default App;
