import React, { Component } from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GOOGLE);
ReactGA.pageview(window.location.pathname + window.location.search);
require('./NotFoundPage.scss');

class NotFoundPage extends Component {
    render() {
        return (
            <div className="l-not-found-page text -black -xl">
                404 page not found
            </div>
        );
    }
}

export { NotFoundPage };