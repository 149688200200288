import React, { useState, useEffect, Fragment } from 'react';
import './Filters.scss';

const Filters = ({
  filters,
  isFiltered,
  filteredProjects,
  selectedFilters,
  updateFilter,
  clearFilters,
}) => {
  const [tooltip, toggleTooltip] = useState(true);
  const [currentFilters, setCurrentFilters] = useState(filters || []);
  const [page, setPage] = useState(0);

  const stop = (e) => {
    e.stopPropagation();
  };
  const goBack = (e) => {
    if (e) stop(e);
    let newParent = filters;
    const { parent } = (currentFilters && currentFilters[0]) || {};
    if (parent) {
      const parents = parent.split('.');
      const getParent = (p, field = 0) => {
        const newP = p.find((f) => f.label === parents[field]);
        if (newP) {
          newParent = p;
          if (parents[field + 1]) {
            getParent(newP.children, field + 1);
          }
        }
      };
      getParent(filters);
    }
    setCurrentFilters(newParent);
  };
  const clear = (e) => {
    stop(e);
    clearFilters(true);
    setCurrentFilters(filters);
  };

  const setChildrenFilters = (f) => {
    if (f.children) {
      const availableChildren = f.children.filter((child) => !!child.count);
      if (availableChildren.length) {
        if (availableChildren[0].date) {
          setCurrentFilters(chunkYears(availableChildren));
        } else {
          setCurrentFilters(availableChildren);
        }
      }
    } else {
      goBack();
    }
    if (f.field) {
      setPage(0);
      updateFilter(f);
    }
  };

  const more = (add = 1) => {
    setPage(page + add);
  };
  const chunkYears = (arr) => {
    const { innerWidth } = window;
    const max = innerWidth / 100;
    const arrayChunks = (array, chunk_size) =>
      Array(Math.ceil(array.length / chunk_size))
        .fill()
        .map((_, index) => index * chunk_size)
        .map((begin) => array.slice(begin, begin + chunk_size));

    return arrayChunks(arr, max);
  };

  const buttons = () => {
    const arr = [];
    const isTopFilters = currentFilters && currentFilters[0] && !currentFilters[0].parent;
    if (isFiltered) {
      arr.push('x');
    }
    if (!isTopFilters) {
      arr.push('<');
    }
    return arr;
  };

  const oneFilter = (currF) => {
    const isSelected =
      currF.children &&
      currF.children.find((child) => selectedFilters.find((s) => s.field === child.field));
    const f = isSelected || currF;
    f.selected = isSelected;
    return (
      <span
        key={currF.label}
        className={`filter-one${f.selected ? ' selected' : ''}${f.date ? ' date' : ''}`}
        onClick={(e) => {
          stop(e);
          setChildrenFilters(f);
        }}
      >
        {f.label}
        {f.count ? ` (${f.count})` : ''}
      </span>
    );
  };

  const moreButtons = (f) => {
    const b = [];
    if (page > 0) {
      b.push({ label: '<', add: -1 });
    }
    if (page + 1 < currentFilters.length) {
      b.push({ label: '>', add: 1 });
    }
    return (
      <Fragment>
        {f.map((p) => oneFilter(p))}
        <div className="filter-button-more" onClick={stop}>
          {b.map((b) => (
            <span key={b} className={`filter-more`} onClick={() => more(b.add)}>
              {b.label}
            </span>
          ))}
        </div>
      </Fragment>
    );
  };

  return (
    <div
      className="l-work__filter-container filter-bar"
      onClick={stop}
      onMouseOver={() => {
        if (tooltip) toggleTooltip(false);
      }}
    >
      <div className={`filter-tooltip${tooltip ? '' : ' hidden'}`}>filters</div>
      <div className="filter-buttons" onClick={stop}>
        {buttons().map((b) => (
          <span key={b} className={`filter-b`} onClick={b === 'x' ? clear : goBack}>
            {b}
          </span>
        ))}
      </div>
      <div className="filter-count">All ({filteredProjects.length})</div>
      <div className="filters-current" onClick={stop}>
        {currentFilters.map((f, i) =>
          Array.isArray(f) ? (page === i ? moreButtons(f) : null) : oneFilter(f)
        )}
      </div>
    </div>
  );
};

export default Filters;
