import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const New = (props) => {
  const { n, i, position } = props;
  return (
    <div className="new-wrapper">
      <a
        // onMouseOver={() => showPic(n.fields.imagenNoticia)}
        // onMouseOut={() => hidePic()}
        href={n.fields.linkNoticia}
        target="_blank"
        key={i}
        className="l-news__content text -black"
        rel="noopener noreferrer"
      >
        <span className="text -secondary -light -s">
          {moment(n.fields.fecha).format("DD/MM/YYYY")}
        </span>
        <h1 className="l-news__content__title text -xxl -bold">
          {n.fields.titulo}
        </h1>
        <p className="text -s -black -secondary -pointer">
          {n.fields.texto && n.fields.texto}
        </p>
        {n.fields.proyectoLink && (
          <h3>
            <Link
              className="text -s -secondary -pointer"
              to={`/project-detail/${n.fields.proyectoLink.fields.titulo
                .normalize("NFD")
                .replace(/\s\s+/g, " ")
                .replace(/[\u0300-\u036f]/g, "")
                .split(" ")
                .join("-")}`}
            >
              Show project ->
            </Link>
          </h3>
        )}
      </a>
      {n.fields && (
        <img
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`,
          }}
          className="l-new__pic"
          src={n.fields.imagenNoticia.fields.file.url}
        />
      )}
    </div>
  );
};

export default New;
