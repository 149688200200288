import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Draggable from 'react-draggable';
import moment from 'moment';
import { analitycEvent } from '../../utilities/analytics';
import Div100vh from 'react-div-100vh'

import menuSVG from './assets/menu.svg';
import menuSVGWhite from './assets/menuWhite.svg';
import menuCross from './assets/cross.svg';

require('./Header.scss');

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverTitle: false,
      showMobileMenu: false,
      movingTitle: false,
      reload: false,
    };
  }

  componentDidUpdate() {
    if (this.props.location.pathname === '/work/all' && this.state.reload)
      document.location.reload();
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/work/all' && !this.state.reload) {
      this.setState({
        reload: true,
      });
    }
  }

  changeTitle = (hoverTitle) => {
    analitycEvent('Navegación', 'Cambiar contenido título', 'Menú superior');

    this.setState({
      hoverTitle,
    });
  };

  buildTitle = () => {
    const { hoverTitle } = this.state;

    return hoverTitle ? 'temperaturasextremas' : 'amanncanovasmaruri';
  };

  redirectHome = () => {
    const { movingTitle } = this.state;
    const { history } = this.props;

    if (!movingTitle) {
      this.setState(
        {
          movingTitle: false,
        },
        () => {
          history.push('/');
        }
      );
    }
  };

  openMenu = () => {
    const { showMobileMenu } = this.state;

    analitycEvent('Navegación', 'Abrir / Cerrar menu móvil', 'Menú superior');

    this.setState({
      showMobileMenu: !showMobileMenu,
    });
  };

  moveTitle = () => {
    analitycEvent('Navegación', 'Arrastrar título', 'Menú superior');

    this.setState({
      movingTitle: true,
    });
  };

  stopMoveTitle = () => {
    setTimeout(() => {
      this.setState({
        movingTitle: false,
      });
    }, 500);
  };

  render() {
    const { showMobileMenu } = this.state;
    const { location, history } = this.props;
    const title = this.buildTitle();
    const menuSVGColor = location.pathname !== '/' ? menuSVG : menuSVGWhite;
    const blackCircle = ['/office', '/news', '/what'];
    const isBlackCircle = blackCircle.includes(location.pathname);

    return (
      <Fragment>
        <div className="c-header-mobile-button" onClick={() => this.openMenu()}>
          <img src={!showMobileMenu ? menuSVGColor : menuCross} alt="burguer menu" />
        </div>
        <Div100vh className={`c-header-mobile ${showMobileMenu ? '-show' : '-hidden'}`}>
          <div className="c-header-mobile__relative">
            <div>
              <Link
                className="text -black -pointer -xxl -bold black-circle"
                to="/office"
                onClick={() => this.openMenu()}
              >
                office
              </Link>
            </div>
            <div>
              <Link
                className="text -black -pointer -xxl -bold black-circle"
                to="/work/all"
                onClick={() => this.openMenu()}
              >
                work
              </Link>
            </div>
            <div>
              <Link
                className="text -black -pointer -xxl -bold black-circle"
                to="/news"
                onClick={() => this.openMenu()}
              >
                news
              </Link>
            </div>
            <div>
              <Link
                className="text -black -pointer -xxl -bold black-circle"
                to="/what"
                onClick={() => this.openMenu()}
              >
                what
              </Link>
            </div>
            <div className="c-header-mobile__relative__footer black-circle">
              <div className="text -light -secondary -left black-circle">
                {moment().format('YYYY')} | temperaturas extremas arquitectos
              </div>
              <div className="text -light -secondary -right">
                proudly baked by{' '}
                <b>
                  <a className="text -carniceria" href="http://www.carniceria.org/" target="_blank">
                    |carniceria
                  </a>
                </b>
              </div>
            </div>
          </div>
        </Div100vh>
        <header className={`c-header ${isBlackCircle ? '-black-circle' : ''}`}>
          <div>
            <Link
              className={`hidden-mobile text ${
                location.pathname === '/' ? '-white -shadow' : 'menu-text-header-item'
              } -bold   -xl`}
              to="/office"
            >
              office
            </Link>
          </div>
          <Draggable
            onDrag={() => this.moveTitle()}
            onStop={() => this.stopMoveTitle()}
            handle=".handle"
            onClick={() => this.redirectHome()}
          >
            <span
              className={`c-header__title text ${
                location.pathname === '/' ? '-white -shadow' : 'menu-text-header-item'
              } -bold -xl -pointer handle`}
              onMouseEnter={() => this.changeTitle(true)}
              onMouseLeave={() => this.changeTitle(false)}
              onClick={() => this.redirectHome()}
            >
              {title}
            </span>
          </Draggable>
          <div>
            <Link
              className={`hidden-mobile  text ${
                location.pathname === '/' ? '-white -shadow' : 'menu-text-header-item'
              } -bold -xl`}
              to="/work/all"
            >
              work
            </Link>
          </div>
        </header>
      </Fragment>
    );
  }
}

export { Header };
