import React, { useState, useEffect } from 'react';

import './Cursor.scss';

const Cursor = () => {
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 });
  const onMouseMove = ({ clientX, clientY }) => {
    setPosition({ x: clientX, y: clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  const hovered = document.elementsFromPoint(x, y)[1];
  const hoverable = ['SPAN', 'A', 'BUTTOM'];

  const noCursor =
    hovered && hovered.className && hovered.className.toString().includes('no-cursor');

  const clickable =
    hovered &&
    noCursor &&
    (hovered.className.includes('clickable') ||
      hoverable.some((h) => h === hovered.tagName) ||
      hovered.attributes.onclick);

  return (
    !noCursor &&
    !!x && !!y && (
      <div
        style={{ left: x, top: y + window.scrollY }}
        className={`cursor ${clickable ? 'clickable-cursor' : 'main-cursor'}`}
      />
    )
  );
};

export default Cursor;
