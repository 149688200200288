import React, { Component, Fragment } from 'react';
import * as contentful from 'contentful';
import ReactGA from 'react-ga';
import igIcon from './assets/igicon.svg';
import emailIcon from './assets/email.svg';
import houseIcon from './assets/house.svg';
import { Hidden, Box } from '@material-ui/core';
import ImgOffice from './ImgOffice';
import { analitycEvent } from '../../utilities/analytics';


const Markdown = require('react-markdown');

ReactGA.initialize(process.env.REACT_APP_GOOGLE);
ReactGA.pageview(window.location.pathname + window.location.search);

const client = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const arrayPictures =
  window.innerWidth > 768
    ? {
        zero: ['50%', '50%', '50%', '50%'],
        one: ['40%', '60%', '40%', '20%'],
        two: ['60%', '40%', '20%', '40%'],
        three: [''],
      }
    : {
        zero: ['90%', '90%', '50%', '50%'],
      };

const titles = ['zero', 'one', 'two'];

require('./Office.scss');

class Office extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      people: null,
      officeInformation: null,
      imagePeople: null,
    };
  }

  componentDidMount() {
    this.fetchPostsPeople();
    this.fetchPostsOffice();

    document.getElementById('bodyBody').classList.add('black-circle');
  }

  fetchPostsPeople = () => {
    client
      .getEntries({
        content_type: 'personas',
        limit: 1000,
      })
      .then((response) => {
        this.setPostsPeople(response.items);
      });
  };

  fetchPostsOffice = () => {
    client
      .getEntries({
        content_type: 'officeInformation',
        limit: 1000,
      })
      .then((response) => {
        this.setPostsOffice(response.items);
      });
  };

  setPostsPeople = (response) => {
    this.setState({
      people: response,
    });
  };

  setPostsOffice = (response) => {
    this.setState({
      officeInformation: response,
    });
  };

  setImageBackground = (imagePeople) => {
    analitycEvent('Interacción', 'Mostrar imagén', 'Oficina');
    this.setState({
      imagePeople,
    });
  };

  buildBoxContactLinks = () => {
    const { officeInformation } = this.state;
    const fields = officeInformation[0].fields;
 

    return (
      <div className="l-office__box-contact-links">
        {fields.address && (
          <div>
            <Hidden smDown>
              <Markdown source={fields.address} />
            </Hidden>
            <Hidden mdUp>
              <a
                href="https://www.google.es/maps/place/Calle+de+la+Golondrina,+80,+28023+Madrid/@40.4504804,-3.7863475,362m/data=!3m1!1e3!4m5!3m4!1s0xd4186555c52fdd5:0x77059e642bbef49f!8m2!3d40.4504804!4d-3.7852534"
                target="_blank"
              >
                <Box mr={1.5}>
                  <img
                    className="l-office__box-contact-links__container__icon email-image"
                    target="_blank"
                    src={houseIcon}
                    alt="email"
                  />
                </Box>
              </a>
            </Hidden>
          </div>
        )}
        {fields.email && (
          <div className="l-office__box-contact-links__container">
            <a className="text -secondary -xsm -black -light-hover" href={`mailto:${fields.email}`}>
              <span className="l-office__box-contact-links__container__email-text">
                {fields.email}
              </span>
              <img
                className="l-office__box-contact-links__container__icon email-image"
                target="_blank"
                src={emailIcon}
                alt="email"
              />
            </a>
          </div>
        )}
        {fields.urlInstagram && (
          <div>
            <a className="text -secondary -xsm -black" href={fields.urlInstagram} target="_blank">
              <img
                className="l-office__box-contact-links__container__icon"
                target="_blank"
                src={igIcon}
                alt="instagram"
              />
            </a>
          </div>
        )}
        {fields.urlTwitter && (
          <div>
            <a href={fields.urlTwitter} target="_blank">
              Twitter
            </a>
          </div>
        )}
        {fields.urlFacebook && (
          <div>
            <a className="text -secondary -xsm -black" href={fields.urlFacebook} target="_blank">
              Facebook
            </a>
          </div>
        )}
      </div>
    );
  };

  buildPeopleInformation = () => {
    const { people, officeInformation } = this.state;
    let information = [];

    if (officeInformation) {
      information.push(
        <div className="l-office__content__section">
          <h2
            onMouseEnter={() =>
              this.setImageBackground(
                officeInformation[0].fields.imagenOffice
                  ? officeInformation[0].fields.imagenOffice.fields.file.url
                  : null
              )
            }
            onMouseLeave={() => this.setImageBackground(null)}
            className="text -bold -xxl"
          >
            office
          </h2>
          <p className="text -s -secondary">
            <Markdown source={officeInformation[0].fields.descripcion} />
          </p>
        </div>
      );
    }

    if (people) {
      information.push(
        <Fragment>
          <div className="l-office__content__section">
            <h2 className="text -bold -xxl">founders</h2>
            {people
              .filter((p) => p.fields.founder)
              .map((content, index) => {
                return (
                  <div
                    onMouseEnter={() =>
                      this.setImageBackground(
                        content.fields.imagen ? content.fields.imagen.fields.file.url : null
                      )
                    }
                    onMouseLeave={() => this.setImageBackground(null)}
                    className="text -s l-office__content__name -secondary"
                    key={index}
                  >
                    {content.fields.nombre}
                  </div>
                );
              })}
          </div>
          <div className="l-office__content__section">
            <h2 className="text -bold -xxl">team</h2>
            {people
              .filter((p) => p.fields.team)
              .map((content, index) => {
                return (
                  <div
                    onMouseEnter={() =>
                      this.setImageBackground(
                        content.fields.imagen ? content.fields.imagen.fields.file.url : null
                      )
                    }
                    onMouseLeave={() => this.setImageBackground(null)}
                    className="text -s l-office__content__name -secondary"
                    key={index}
                  >
                    {content.fields.nombre}
                  </div>
                );
              })}
          </div>
        </Fragment>
      );
    }

    return information;
  };

  render() {
    const { imagePeople, officeInformation } = this.state;
    const positions =
      window.innerWidth > 768
        ? arrayPictures[titles[Math.floor(Math.random() * 2)]]
        : arrayPictures[titles[0]];
    return (
      <Fragment>
        <main className="l-office">
          {officeInformation && this.buildBoxContactLinks()}
          {imagePeople && (
           
              <ImgOffice imagePeople={imagePeople} />
           
          )

          /* <div
              style={{
                width: positions[0],
                height: positions[1],
                top: positions[2],
                left: positions[3],
                backgroundImage: `url(${imagePeople})`,
              }}
             className="l-office__image-section"
            >
              {}
            </div> */
          }
          <div className="l-office__content">
            {this.buildPeopleInformation()}
            <div className="text -secondary l-office__content__carniceria">
              2020 | This website is proudly baked by
              <a
                href="https://www.carniceria.org/"
                target="_blank"
                className="text -carniceria -pointer"
              >
                |carnicería
              </a>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}

export { Office };
